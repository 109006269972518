import dispatchEvent from '../dispatch-event';
import libcryptobox from '../libcryptobox';
import { nanoid } from 'nanoid';

const jsonrpc = "2.0";

// JSON-RPC client.
export default class RpcClient {
  constructor(transport) {
    this._transport = transport;
    this._proxy = {};
    this._inflight = {};
    this._pending = [];

    this.onready = null;
    this.onerror = null;
    this.onclose = null;
  }

  connect() {
    this._transport.onready = async () => {
      if (this._ready) {
        return;
      }
      this._ready = true;
      console.info("rpc client: ready");
      await Promise.resolve(dispatchEvent(this, "ready"));
      this._pending.forEach((message) => this._send(message));
      delete this._pending;
    };
    this._transport.onmessage = (message) => this._handle(message);
    this._transport.onerror = (error) => {
      console.error("rpc client:", error);
      dispatchEvent(this, "error", error);
    };
    this._transport.onclose = () => {
      console.log("rpc client: close");
      dispatchEvent(this, "close");
    };
    this._transport.connect();
  }

  close() {
    this._transport.close();
  }

  call(method, params, options = {}) {
    options?.signal?.throwIfAborted();
    let id = nanoid();
    let promise = new Promise((resolve, reject) => {
      this._inflight[id] = { resolve, reject, options };
      this._send({ jsonrpc, id, method, params });
    });
    promise.cancel = () => {
      this._send({ jsonrpc, method: "__cancel", params: [id] });
    };
    options?.signal?.addEventListener("abort", () => {
      this._send({ jsonrpc, method: "__cancel", params: [id] });
    });
    this._inflight[id].promise = promise;
    return promise;
  }

  proxy(object) {
    if (!object) {
      return undefined;
    }
    let id = nanoid();
    this._proxy[id] = object;
    return id;
  }

  _send(message) {
    if (this._ready) {
      this._transport.send(message);
    } else {
      this._pending.push(message);
    }
  }

  _handle(message) {
    if (message.result !== undefined || message.error) {
      // response
      let inflight = this._inflight[message.id];
      if (inflight) {
        if (message.error) {
          dispatchEvent(this, "error", message.error);
          inflight.reject(message.error);
        } else {
          inflight.resolve(message.result);
        }
        delete this._inflight[message.id];
      } else {
        console.warn(`rpc client: no request inflight with id ${message.id}`);
      }
    } else if (message.method === "__onProgress") {
      // progress notification
      const id = message.params[0];
      const inflight = this._inflight[id];
      if (inflight) {
        const timestamp = message.params[1];
        const processed = message.params[2];
        const total = message.params[3];

        inflight.promise?.onprogress?.(timestamp, processed, total);
        inflight.options?.onprogress?.(timestamp, processed, total);
      }
    } else if (message.method === "__finalize") {
      // dealloc notification
      delete this._proxy[message.params[0]];
    } else if (message.method) {
      // request - or notification
      let object = this._proxy[message.params[0]];
      let method = message.method.substr(message.method.indexOf(".") + 1);
      let params = message.params.slice(1);
      let id = message.id;
      if (object && typeof object[method] === "function") {
        let p = new Promise((resolve) => {
          resolve(object[method].apply(object, params));
        });
        if (id) {
          p.then((result) => {
            if (result === undefined) {
              result = null;
            }
            this._send({ jsonrpc, result, id });
          }).catch((error) => {
            if (error.name === "AbortError") {
              this._send({
                jsonrpc,
                error: {
                  code: libcryptobox.ErrorCode.Canceled,
                  details: error.toString(),
                },
                id,
              });
            } else {
              this._send({ jsonrpc, error: error.toString(), id });
            }
          });
        }
      } else if (id) {
        this._send({
          jsonrpc,
          error: `rpc client: no object with id ${message.params[0]}`,
          id,
        });
      }
    }
  }
}
