import Component from '@glimmer/component';
import ArrayProxy from '@ember/array/proxy';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class TreeItemsFolder extends Component {
  @tracked fileListArray = [];

  @service('transfers') transfersManager;

  @action
  async drop(e) {
    const space = await this.args.folder.get('space');
    this.transfersManager.handleDrop(space, this.args.folder, e);
  }

  @action
  oncontextmenu() {
    this.args.onContextMenu(this.args.folder);
  }

  @action
  handleClick() {
    this.args.actionClick();
  }
}
