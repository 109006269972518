import { inject as service } from '@ember/service';
import { action } from "@ember/object";
import Component from "@glimmer/component";
import { tracked } from '@glimmer/tracking';

export default class FolderInputComponent extends Component {
  @tracked value = null;

  @service transfers;
  @service intl;

  get useShowDirectoryPicker() {
    return 'showDirectoryPicker' in window;
  }

  get helpText() {
    return this.useShowDirectoryPicker ? this.intl.t('group.actions.uploadFolder') : this.intl.t('group.actions.uploadFolderLimited');
  }

  @action
  async click(e) {
    e.stopPropagation();
    e.preventDefault();
    const handle = await window.showDirectoryPicker();
    const { files, emptyDirs } = await this.transfers.readFileSystemDirectoryHandle(handle);
    this.transfers.createFolders(this.args.targetSpace, this.args.currentFolder, emptyDirs);
    this.transfers.uploadFolderFilesList(files, this.args.currentFolder, this.args.targetSpace);
  }

  @action
  change(e) {
    e.stopPropagation();
    e.preventDefault();
    let files = [].slice.call(e.target.files);
    if (!files[0].webkitRelativePath || (files.length === 0 && !e.target.webkitEntries)) {
      // La liste de fichiers ou le navigateur n'est pas compatible, éviter l'action de téléchargement du dossier.
      this.value = null;
      return false;
    }
    let fileListArray = [];
    files.forEach((file) => {
      file.CBRelativePath = file.webkitRelativePath;
      fileListArray.push(file);
    });

    this.transfers.uploadFolderFilesList(fileListArray, this.args.currentFolder, this.args.targetSpace);
    this.value = null;
  }
}
