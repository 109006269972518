import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class DocumentsList extends Component {
  @tracked isDragOver = false;
  @tracked fileListArray = [];
  @tracked isContextMenuOpened = false;
  @tracked mouseX = 0;
  @tracked mouseY = 0;

  @service account;
  @service notify;
  @service intl;
  @service('transfers') transfersManager;

  @computed('args.targetSpace.fileCount', 'targetSpace')
  get fileCount() {
    if (this.args.targetSpace) return this.args.targetSpace.fileCount;
    return 0;
  }

  @computed(
    'account.status.canWrite',
    'args.targetSpace.{isDeleted,isReader,isViewer}',
    'args.{isSearchResults,weAreInPast}',
    'isSearchResults',
    'targetSpace.{isDeleted,isReader}',
  )
  get isEditable() {
    return (
      !this.args.targetSpace.get('isReader') &&
      !this.args.targetSpace.get('isViewer') &&
      this.account.status.canWrite &&
      !this.args.targetSpace.get('isDeleted') &&
      !this.args.isSearchResults &&
      !this.args.weAreInPast
    );
  }

  @action
  dragOver(e) {
    if (!this.isEditable) {
      return false;
    }
    this.isDragOver = true;
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
  }

  @action
  dragLeave(e) {
    if (!this.isEditable) {
      return false;
    }
    this.isDragOver = false;
    e.preventDefault();
    e.stopPropagation();
  }

  /**
   * we drop one or more files on the file list, upload them to backend.
   * @param e
   * @returns {boolean}
   */
  @action
  async drop(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!this.isEditable) {
      return false;
    }
    this.isDragOver = false;
    const targetSpace = this.args.targetSpace;
    const currentFolder = this.args.currentFolder;
    /**
     * manage errors
     */
    if (e.dataTransfer.types.length === 0) {
      this.notify.warning(
        this.intl.t('documents.dropToast.noAccessToData.message'),
        {
          title: this.intl.t('documents.dropToast.noAccessToData.title'),
          closeAfter: 10000,
        },
      );
      return false;
    }
    if (e.dataTransfer.types.length > 2) {
      this.notify.warning(this.intl.t('documents.dropToast.notFile.message'), {
        title: this.intl.t('documents.dropToast.notFile.title'),
      });
      return false;
    }
    this.transfersManager.handleDrop(targetSpace, currentFolder, e);
  }

  @action
  handleRightClick(e) {
    e.stopPropagation();
    if (this.isContextMenuOpened) {
      this.handleChangeContextMenu(false);
    }
    this.mouseX = e.pageX;
    this.mouseY = e.pageY;
    const search = document.querySelector("input[name='search']");

    if (search) {
      const searchBounds = search.getBoundingClientRect();
      const searchX = searchBounds.left;
      const searchY = searchBounds.top;
      const xValid =
        e.pageX < searchX || e.pageX > searchX + searchBounds.width + 50;
      const yValid =
        e.pageY < searchY || e.pageY > searchY + searchBounds.height + 30;

      if (xValid || yValid) {
        e.preventDefault();
        this.isContextMenuOpened = true;
      }
    } else {
      e.preventDefault();
      this.isContextMenuOpened = true;
    }
  }
  @action
  handleCloseContextMenu() {
    this.isContextMenuOpened = false;
  }
}
